#alif-banner h1{
    font-size: 4rem !important;
}
#alif-banner h2{
    font-size: 3.5rem !important;
}
#alif-banner h3{
    font-size: 3.25rem !important;
}
#alif-banner h4{
    font-size: 3rem !important;
}
#alif-banner h5{
    font-size: 2.75rem !important;
}
#alif-banner h6{
    font-size: 2.5rem !important;
}
.admin-div-body{
    width: 100%;
    height: 100%;
    padding-top: 26px;
    padding-left: 126px;
    background-color: #fff9db;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
    margin-top: 20px !important;
    color: #fff !important;
}
.img-fluid {
    width: 100%;
    height: auto;
}
.p-text {
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.admin-slider{
    width: 100%;
    height: 500px;
}
.full-screen {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.img-alif-john{
    border: 3px solid #cba235 !important;
    border-radius: 4px !important;
}
.logo {
    max-width: 75px;
}
.slick-dots{
    bottom: 10px !important;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:before {
    color: #cba235 !important;
}
.slick-dots li.slick-active button {
    box-shadow: 0 0 0 2px #cba235 !important;
}
.banner {
    width: 100%;
}
nav {
    float: left;
    width: 100%;
    z-index: 100;
    border-bottom: solid 1px #ffffff45;
}
.navbar-nav {
    margin-left: auto;
}
.header {
    position: absolute;
    width: 100%;
}
#header-body{
    position: relative;
}
#header-main{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #0000003F, #0000002F, #0000001F, #0000001A, #00000000, #00000000); 
}
@media only screen and (max-width: 1190px) {
    #header-main{
        background: linear-gradient(90deg, #0000, #000000a3) !important
    }
}
.nav-link {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin: 1px;
    -webkit-transition: all .4s;
    transition: all .4s;
    padding-right: 12px;
    padding-left: 12px;
    font-family: 'Barlow', sans-serif;
}
.navbar-brand {
    position: absolute;
    left: 0;
    top: -1px;
    background: #000;
    width: 130px;
    text-align: center;
    padding: 5px 0;
    border: solid 1px #e3c25b;
}
.carousel-caption-h5 {
    font-family: 'Barlow', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #ffc93b;
    margin-bottom: 15px;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
    letter-spacing: 6px;
}
.carousel-caption-h3 {
    position: relative;
    margin-bottom: 0;
    font-size: 75px;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
}
.carousel-caption-h2 {
    position: relative;
    margin-bottom: 0;
    font-size: 75px;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    font-weight: 700;
    letter-spacing: 2px;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    padding-bottom: 45px;
}
/* ======= About style ======= */
.about-list {
    position: relative;
    display: block;
}
.about-list li {
    position: relative;
    display: flex;
    align-items: center;
}
.about-list li+li {
    margin-top: 10px;
}
.about-list-icon {
    display: flex;
    align-items: center;
}
.about-list-icon span {
    font-size: 10px;
    color: #fff;
    background-color: #cca234;
    border-radius: 50%;
    padding: 10px;
}
.about-list-text {
    margin-left: 15px;
}
.about-list-text p {
    font-size: 16px;
    margin: 0;
}
.about h5 {
    color: #0f2454;
    font-size: 18px;
    line-height: 1.75em;
}
.about h5 span {
    color: #cca234;
}
.about .about-img img:hover {
    transform: scale(0.95);
}
.about .about-img {
    position: relative;
}
.about .about-img .img {
    padding: 0 30px 30px 15px;
    position: relative;
}
.about .about-img .img:before {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    left: 45px;
    bottom: 0;
    background-color: #cca234;
}
.about .about-img .img:after {
    content: '';
    width: 50%;
    position: absolute;
    top: -6%;
    bottom: 60%;
    left: -3%;
    background-repeat: repeat;
    z-index: -1;
    /* background-image: url(../img/dots.png); */
}
.about .about-img .img img {
    position: relative;
    z-index: 2;
    background: transparent;
}
.about #circle {
    position: absolute;
    top: 200px;
    right: -40%;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    pointer-events: none;
    opacity: .99;
    z-index: 2;
}
.about #circle text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.about #circle svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
@keyframes rotate {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
.about {
    float: left;
    width: 100%;
    position: relative;
}
.section-subtitle {
    color: #000;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 5px;
}
.section-title {
    font-size: 45px;
    line-height: 1.2em;
    font-weight: 700;
    color: #0f2454;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.section-title span {
    color: #cca234;
}
p {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75em;
    color: #676977;
    margin-bottom: 20px;
    text-align: justify;
}
.section-padding {
    padding: 120px 0;
    float: left;
    width: 100%;
    background: #fff;
}
.phone-call {
    display: flex;
    align-items: center;
}
.phone-call .icon {
    position: relative;
    top: 3px;
}
.phone-call .icon span {
    font-size: 36px;
    color: #cca234;
}
.phone-call .text {
    margin-left: 15px;
}
.phone-call .text p {
    font-size: 16px;
    margin: 0;
}
.phone-call .text a {
    font-size: 24px;
    color: #cca234;
    font-family: 'Barlow', sans-serif;
}
/* ======= Tours and Destination 1 style ======= */
.tours1 .item, .destination1 .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}
.tours1 .item:hover img, .destination1 .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.tours1 .item:hover .con, .destination1 .item:hover .con {
    bottom: 0;
}
.tours1 .item img, .destination1 .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
}
.tours1 .item .category, .destination1 .item .category {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
    writing-mode: vertical-rl;
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    text-orientation: mixed;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Barlow', sans-serif;
    padding: 12px 6px;
    line-height: 1.5em;
    text-transform: uppercase;
    box-shadow: 0px 0px 35px 0px rgb(0 0 0 / 5%);
    background: #cca234;
}
.tours1 .item .category a, .destination1 .item .category a {
    color: #fff;
}
.tours1 .item .category:hover, .destination1 .item .category:hover {
    color: #fff;
}
.tours1 .item .con, .destination1 .item .con {
    padding: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
    text-align: left;
    z-index: 20;
    height: auto;
    box-sizing: border-box;
    background: -moz-linear-gradient(top, transparent 0, rgba(15, 36, 84, .1) 2%, rgba(15, 36, 84, .75) 90%);
    background: -webkit-linear-gradient(top, transparent 0, rgba(15, 36, 84, .01) 2%, rgb(204 162 52 / 51%) 90%);
    background: linear-gradient(to bottom, transparent 0, rgba(15, 36, 84, .01) 2%, rgb(204 162 52 / 38%) 90%);
}
.tours1 .item .con h6, .tours1 .item .con h6 a, .destination1 .item .con h6, .destination1 .item .con h6 a {
    position: relative;
    color: #fff;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    display: inline;
}
.tours1 .item .con h6 i, .destination1.item .con h6 i {
    font-size: 6px;
    margin-right: 2px;
}
.tours1 .item .con h5, .tours1 .item .con h5 a, .destination1 .item .con h5, .destination1 .item .con h5 a {
    position: relative;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 10px;
}
.tours1 .item .con p, .destination1 .item .con p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}
.tours1 .item .con span, .destination1 .item .con span {
    color: #fff;
    font-size: 10px;
}
.tours1 .item .con i, .destination1 .item .con i {
    color: #fff;
}
.tours1 .item .con i:hover, .destination1 .item .con i:hover {
    color: #fff;
}
.tours1 .item .con .butn-light, .destination1 .item .con .butn-light {
    margin-left: 0;
    border-radius: 30px;
    border-color: #676977;
    padding: 11px 30px;
}
.tours1 .item .con .butn-light:hover, .destination1 .item .con .butn-light:hover {
    border-color: #2095AE;
}
.tours1 .item .line, .destination1 .item .line {
    text-align: center;
    height: 1px;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: auto 0 20px;
}
.tours1 .item:hover .line, .destination1 .item:hover .line {
    width: 100%;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}
.tours1 .facilities ul, .destination1 .facilities ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
/* ======= CountUp Numbers style ======= */
.numbers {
    background-attachment: fixed;
}
.numbers .item img {
    position: absolute;
    width: 50%;
    right: -30%;
    top: 30%;
    z-index: 4;
}
.numbers .item img.tobotm {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    top: 15%;
}
.numbers .item:hover .icon .front {
    -webkit-transform: translateX(-120%);
    transform: translateX(-120%);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.numbers .item:hover .icon .back {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.numbers .item .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 50%;
    border: 7px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.numbers .item .icon span, .numbers .item .icon i {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    line-height: 80px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.numbers .item .icon .back {
    -webkit-transform: translateX(120%);
    transform: translateX(120%);
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: #fff;
}
.numbers .item h3 {
    font-weight: 800;
    margin-bottom: 10px;
    font-size: 36px;
    color: #fff;
}
.numbers .item h6 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.numbers .item h6 span {
    font-size: 16px;
    color: #2095AE;
    margin-right: 5px;
}
ol.number {
    list-style: none;
    counter-reset: item;
    margin-bottom: 20px;
}
ol.number li {
    counter-increment: item;
    margin-bottom: 10px;
}
ol.number li:before {
    margin-right: 10px;
    content: counter(item);
    background: #2095AE;
    border-radius: 100%;
    color: #fff;
    width: 26px;
    height: 26px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    line-height: 27px;
}
/* ======= Tours and Destination 1 style ======= */
.tours1 .item, .destination1 .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}
.tours1 .item:hover img, .destination1 .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.tours1 .item:hover .con, .destination1 .item:hover .con {
    bottom: 0;
}
.tours1 .item img, .destination1 .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
}
.tours1 .item .category, .destination1 .item .category {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
    writing-mode: vertical-rl;
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    text-orientation: mixed;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Barlow', sans-serif;
    padding: 12px 6px;
    line-height: 1.5em;
    text-transform: uppercase;
    box-shadow: 0px 0px 35px 0px rgb(0 0 0 / 5%);
    background: #cca234;
}
.tours1 .item .category a, .destination1 .item .category a {
    color: #fff;
}
.tours1 .item .category:hover, .destination1 .item .category:hover {
    color: #fff;
}
.tours1 .item .con, .destination1 .item .con {
    padding: 20px;
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
    text-align: left;
    z-index: 20;
    height: auto;
    box-sizing: border-box;
    background: -moz-linear-gradient(top, transparent 0, rgba(15, 36, 84, .1) 2%, rgba(15, 36, 84, .75) 90%);
    background: -webkit-linear-gradient(top, transparent 0, rgba(15, 36, 84, .01) 2%, rgba(15, 36, 84, .75) 90%);
    background: linear-gradient(to bottom, transparent 0, rgba(15, 36, 84, .01) 2%, rgba(15, 36, 84, .75) 90%);
}
.tours1 .item .con h6, .tours1 .item .con h6 a, .destination1 .item .con h6, .destination1 .item .con h6 a {
    position: relative;
    color: #fff;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    display: inline;
}
.tours1 .item .con h6 i, .destination1.item .con h6 i {
    font-size: 6px;
    margin-right: 2px;
}
.tours1 .item .con h5, .tours1 .item .con h5 a, .destination1 .item .con h5, .destination1 .item .con h5 a {
    position: relative;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 10px;
}
.tours1 .item .con p, .destination1 .item .con p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}
.tours1 .item .con span,
.destination1 .item .con span {
    color: #fff;
    font-size: 10px;
}
.tours1 .item .con i,
.destination1 .item .con i {
    color: #fff;
}
.tours1 .item .con i:hover,
.destination1 .item .con i:hover {
    color: #fff;
}
.tours1 .item .con .butn-light,
.destination1 .item .con .butn-light {
    margin-left: 0;
    border-radius: 30px;
    border-color: #676977;
    padding: 11px 30px;
}
.tours1 .item .con .butn-light:hover,
.destination1 .item .con .butn-light:hover {
    border-color: #2095AE;
}
/* line animation */
.tours1 .item .line,
.destination1 .item .line {
    text-align: center;
    height: 1px;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: auto 0 20px;
}
.tours1 .item:hover .line,
.destination1 .item:hover .line {
    width: 100%;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}
/* facilities */
.tours1 .facilities ul,
.destination1 .facilities ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.destination1 .facilities ul li,
.tours1 .facilities ul li {
    color: #fff;
    font-size: 14px;
    line-height: 1.2em;
    padding-bottom: 0px;
    float: left;
    margin-right: 10px;
    padding-top: 7px;
}
.destination1 .facilities ul li:last-of-type,
.tours1 .facilities ul li:last-of-type {
    margin-right: 0px;
}
.destination1 .facilities ul li i,
.tours1 .facilities ul li i {
    font-size: 14px;
    margin-right: 1px;
}
/* permalink */
.tours1 .item .con .permalink,
.destination1 .item .con .permalink {
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    color: #fff;
    height: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    display: inline;
}
.tours1 .item .con .permalink a,
.destination1 .item .con .permalink a {
    color: #fff;
    text-align: right;
}
.tours1 .item .con .permalink i,
.destination1 .item .con .permalink i {
    font-size: 10px;
    text-align: right;
}
.tours1 .item .con .permalink a:hover,
.tours1 .item .con .permalink i:hover,
.destination1 .item .con .permalink a:hover,
.destination1 .item .con .permalink i:hover {
    color: #2095AE;
}
.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
    line-height: 1.5;
    display: block;
    outline: none;
    margin: 0 auto;
    text-align: center;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #cca234;
    border: 1px solid #cca234;
}
.owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 0 3px;
    border-radius: 50%;
    background: #d7ebf0;
    border: 1px solid #d7ebf0;
}
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}
.btn-1 {
    float: left;
    background: #cca234;
    color: #fff;
    padding: 12px 20px;
}
.bg-01 {
    /* background-image: url(../images/bg-01.jpg); */
    background-repeat: no-repeat;
    background-position: bottom right;
}
/* ======= Blog style ======= */
.blog .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 15px;
}
.blog .section-title {
    color: #2e2d31;
}
.blog .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(75%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.blog .item:hover .con {
    bottom: 0;
}
.blog .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
}
.blog .item .date {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 0;
    color: #fff;
    padding: 10px 10px 5px 10px;
    text-align: center;
    background: #cca234;
}
.blog .item .date span {
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    line-height: 1.5em;
    margin-bottom: 0px;
}
.blog .item .date i {
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 18px;
    font-style: normal;
    color: #fff;
    line-height: 1.5em;
    font-weight: 500;
}
.blog .item .con {
    margin: -100px 0 131px;
    padding: 25px;
    border-radius: 0;
    overflow: hidden;
    -webkit-transition: .2s cubic-bezier(.3, .58, .55, 1);
    transition: .2s cubic-bezier(.3, .58, .55, 1);
    bottom: -90px;
    position: relative;
    background: #fff;
}
.blog .item .con .category {
    font-family: 'Barlow', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    letter-spacing: 2px;
}
.blog .item .con .category a {
    margin-right: 5px;
    position: relative;
    color: #2095AE;
}
.blog .item .con .category a:hover {
    color: #2095AE;
}
.blog .item .con .category a:last-child {
    margin-right: 0;
}
.blog .item .con .category a:last-child:after {
    display: none;
}
.blog .item .con h5,
.blog .item .con h5 a {
    color: #0f2454;
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 1.5em;
}
.blog .item .con h5:hover,
.blog .item .con h5 a:hover {
    color: #0f2454;
}
.blog .item .con p {
    color: #000;
    font-size: 17px;
}
.blog .item .con i {
    color: #fff;
    font-size: 20px;
}
.bg-navy {
    background: #f4f5f8;
}
/* ======= Footer style ======= */
.footer {
    background: #2e2d31;
    float: left;
    width: 100%;
	border-top:solid 6px #cca234;
}
.footer-logo {
    /* width: 160px; */
    margin-bottom: 15px;
    color: #cca234;
}
.footer .widget .widget-title {
    font-size: 20px;
    display: inline-block;
    position: relative;
    color: #fff;
    text-transform: uppercase;
}
/* first footer */
.first-footer {
    padding: 90px 0 0 0;
    /*    border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
}
/* footer icon */
.icon-footer {
    width: 65px;
    max-height: 65px;
    min-height: 65px;
    min-width: 65px;
    background-color: #cca234;
    color: #fff;
    font-size: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}
.icon-footer i {
    width: 100%;
    text-align: center;
}
/* footer link */
.links.dark {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2e2d31;
    box-shadow: none;
    border-radius: 0px;
}
.links.dark.footer-contact-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 45px;
    padding: 20px 20px 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.footer-contact-links-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1032px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.footer-contact-links-divider {
    width: 1px;
    min-height: 100%;
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 255, 255, 0.1);
}
.footer-contact-link-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
}
.image-wrapper.footer-contact-link-icon {
    margin-right: 22px;
}
.footer-contact-link-wrapper h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
}
.footer-contact-link-wrapper p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    margin-bottom: 0px;
}
/* second footer */
.second-footer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.second-footer .widget-area .widget {
    padding: 45px 0;
}
.second-footer .widget-area .widget p {
    color: rgba(255, 255, 255, 0.8);
}
/* usefull links */
.footer .usful-links ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer .usful-links ul li {
    margin-bottom: 5px;
}
.footer .usful-links ul li:hover {
    color: #2095AE;
}
.footer .usful-links ul li i {
    font-size: 7px;
    margin-right: 3px;
}
.footer .usful-links ul li a {
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    padding-left: 0px;
}
.footer .usful-links ul li a:hover {
    color: #2095AE;
}
/* bottom footer */
.copyright {
    padding-top: 30px;
    padding-bottom: 30px;
}
.copyright p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}
.copyright p a {
    color: rgba(255, 255, 255, 0.8);
}
.copyright p a:hover {
    color: #fff;
}
.copyright p span {
    color: rgba(255, 255, 255, 0.01);
    padding: 0 5px;
}
/* social icons */
.social-icons li {
    display: inline-block;
    border: none;
    z-index: 1;
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #2095AE;
    margin-right: 5px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.social-icons li a {
    display: block;
    text-align: center;
    color: #fff;
}
.footer .social-icons li>a {
    font-size: 14px;
}
.social-icons li a:hover {
    background: #1288a2;
    color: #fff;
    border-radius: 100%;
}
/* newsletter */
.widget-newsletter {
    position: relative;
}
.widget-newsletter input {
    height: 60px;
    width: 100%;
    border: none;
    background: #2e2d31;
    font-size: 16px;
    color: #676977;
    outline: none;
    padding: 0 90px 0 20px;
    border-bottom: solid 1px #ffffff47;
}
.widget-newsletter button {
    height: 50px;
    border: none;
    background: #2e2d31;
    font-size: 16px;
    color: #fff;
    outline: none;
    padding: 0 25px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
}
@media screen and (max-width: 767px) {
    .footer-contact-links-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .footer-contact-links-divider {
        width: auto;
        min-height: 1px;
        margin: 20px 0px;
    }
}
footer h2 {
    color: #cca234;
    font-size: 25px;
}
.trs {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
a:hover {
    text-decoration: none !important;
    color: #f3c240;
    font-weight: 600;
}
.background-fixed {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
button:focus {
    outline: none;
}
@media screen and (max-width: 768px) {
    .carousel-caption-h3 {
        font-size: 35px;
    }
    .carousel-caption-h2 {
        font-size: 40px;
    }
    #circle{ 
        display: none; 
    }
    .carousel-caption {
        left: 0;
        right: 0;
    }
    .tour-inner {
        padding: 0;
    }
    .form1 label {
        left: 0;
    }
    .tour-inner select {
        padding: 22px 23px;
    }
    .numbers .col-md-3{ 
        margin-bottom: 50px; 
    }
    .tours1 .col-md-5{ 
        margin-bottom: 70px; 
    }
    .nav-link {
        color: #FFF;
    }
    header{
        background: #fff;
        z-index: 10000;
        position: relative;
    }
    .section-padding {
        padding: 50px 0;
    }
    nav .nav-item {
        margin-left: 0px;
    }
}
nav .nav-item {
    margin-left: 20px;
}
.inner-banner {
    float: left;
    width: 100%;
    margin-top: -60px;
	border-bottom:solid 6px #cca234;
    position: relative;
}
.mt-0{
    margin-top: 0;
}
.active {
    text-decoration: none !important;
    color: #f3c240;
    font-weight: 600;
}
.contact h3 {
    font-size: 27px;
    color: #0f2454;
}
input[type="password"], input[type="email"], input[type="text"], input[type="file"], textarea {
    outline: none;
    max-width: 100%;
    margin-bottom: 0px;
    padding: 20px;
    height: auto;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    display: block;
    width: 100%;
    line-height: 1.5em;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #676977;
}
.right-sidebar .item-form {
    background: #f4f5f8;
    padding: 45px 25px;
}
.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
}
.tab-container .nav{
    float: left;
    width: 100%;
    background: #cca234;
    margin-bottom: 30px;
}
.tab-container .nav li{
    border: solid 1px #00000059;
    border-collapse: collapse;
}
.tab-container .nav li a{
    border-radius: 0;
}
.tab-container .nav li .active{
    margin: 0 0 0 0;
    padding: 9px 0;
}
.modal-body .right-sidebar .item-form {
    padding: 25px 25px;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #cca234 #cca234 #cca234;
    color: #000 !important;
}
.Inclusions{
    float: left;
    width: 100%;
    background: #cccccc36;
}
.Inclusions h2{
    position: relative;
    color: #555;
    font-size: 19px;
    font-weight: 600;
    margin-top: 0px;
    padding: 8px 0;
    line-height: 20px;
    padding-bottom: 0;
    text-align: center;
}
.bloc-video video {
    width: 100%;
    object-fit: cover;
    z-index: -1;
    position:absolute;
    top: 0;
    left: 0;
    height: 100vh;
}
.carousel-caption {
    position: absolute;
    right: 0;
    top: 60%;
    left: 0;
    bottom: 0;
    z-index: 10;
    padding: 0;
    color: #fff;
    text-align: center;
    transform: translateY(-50%);
    margin-right: 36px;
    margin-left: 36px;
}
/**Banner**/
.main-slider {
    width: 100%;
    height: 100vh;
    margin-top: -69px;
}
.slider-item{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-slider.slick-initialized {
    opacity: 1;
    visibility: visible;
}
.slick-slide {
    position: relative;
    height: 38vw;
}
.slick-slide video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
}
.slick-slide .slide-image {
    opacity: 0;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.8s ease
}
.slick-slide .slide-image.show {
    opacity: 1;
}
.slick-slide .image-entity {
    width: 100%;
    opacity: 0;
    visibility: hidden;
}
.slick-slide .loading {
    position: absolute;
    top: 44%;
    left: 0;
    width: 100%;
}
.slick-slide.slick-active {
    z-index: 1;
}
.slick-slide.slick-active .caption {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.7s cubic-bezier(0.32, 0.34, 0, 1.62) 0.6s;
}
.caption {
    position: absolute;
    top: 44%;
    left: 5%;
    text-align: center;
    padding: 20px;
    border: 3px solid;
    color: #fff;
    margin: 0;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.02em;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
    transform: translateY(100px);
}
.slick-dots {
    text-align: center;
    padding-top: 15px;
}
.slick-dots li {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
}
.slick-dots li button {
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 0 transparent;
    vertical-align: middle;
    color: #fff;
    background-color: #fff;
    transition: all 0.3s ease;
    opacity: 0.4;
}
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover {
    opacity: 1;
}
.slick-dots li.slick-active button {
    border-color: #2d3042;
    box-shadow: 0 0 0 2px #fff;
    opacity: 1;
}
.slide-arrow{
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 1;
    background-color: #fff;
    border: none;
    width: 60px;
    height: 60px;
    transition: .3s;
}
.slide-arrow:hover{
    background-color: #cca234;
}
.next-arrow{
    right: 0;
}
.left-arrow{
    left: 0;
}
@-webkit-keyframes slideIn {
    from {
       filter: blur(15px);
    }
    to {
       filter: blur(0);
    }
}
@keyframes slideIn {
    from {
        filter: blur(15px);
    }
    to {
        filter: blur(0);
    }
}
@-webkit-keyframes slideOut {
    from {
        filter: blur(0);
    }
    to {
        filter: blur(15px);
    }
}
@keyframes slideOut {
    from {
        filter: blur(0);
    }
    to{
        filter: blur(15px);
    }
}
@media screen and (max-width: 768px) {
    .bloc-video video {
        height: 50vh;
    }
    .carousel-caption {
        top: 25% !important;
        transform: translateY(0%);
        margin-right: 26px;
        margin-left: 26px;
    }
    .section-padding{
        background-color: transparent;
    }
    .about {
        margin-top: 50px;
    }
    .tours1 .item img, .destination1 .item img {
        width: 100%;
    }
    .inner-banner {
        margin-top: 0px;
    }
    .main-slider{
        height: 100%;
    }
    .slick-slide .slide-image {
        padding: 18px 0;
    }
}
@media screen and (max-width: 400px) {
    .carousel-caption-h5 {
        font-size: 10px;
    }
    .carousel-caption-h3 {
        font-size: 25px;
    }
    .carousel-caption-h2 {
        font-size: 30px;
    }
}
.index2 {
    background: #fff9db;
    border-top: solid 4px #c49b32;
}
.about1 {
    background-size:cover;
}
.white1 {
    color:#ffffff !important;
}
.ziyarat1 {
    background:#cccccc;
}
.umrah-section4{
    width: 100%;
    background-color: #fff9db;
    padding-top: 1px;
    padding-bottom: 6px;
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
@media (max-width: 940px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (max-width: 700px) {
    .dfhbhBB {
        flex-direction: column !important;
    }
    .reuefJE{
        margin-top: 12px !important;
    }
    .table-umrah{
        min-width: 300px !important;
    }
}
.umrah-pkg-2{
    display: none;
}
@media (max-width: 768px){
    .umrah-tab-1{
        display: flex;
        flex-direction: column;
    }
    .umrah-pkg-1{
        display: none;
    }
    .umrah-pkg-2{
        display: block;
    }
}