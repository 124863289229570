/* Animations */
.anim{
    opacity: 0;
}
.zoomIn {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.fadeUp{
    animation: fadeInUp 1s ease backwards;
}
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-webkit-keyframes fadeInUp{
    0%{   
        -webkit-transform: translate(0px, 100px);
        transform: translate(0px, 100px);
        opacity: 0;
    }
    100%{
        -webkit-transform:translate(0px, 0);
        transform:translate(0px, 0);
        opacity: 1;
    }
}
@keyframes fadeInUp{
    0%{   
        transform: translate(0px, 100px);
        opacity: 0;
    }
    100%{
        transform:translate(0px, 0);
        opacity: 1;
    }
}